
import { defineComponent, ref, onMounted, reactive, toRefs, onBeforeUnmount } from 'vue'
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css'
import GC from '@grapecity/spread-sheets'
import { ssHotTop, usetableHeight, getIsEmpty } from './utils'
import blobDownload from '@/utils/blobDownload'
import { UpdateMSRPProps } from './types'
import { getMSRPData, saveMSRPData } from '@/API/sysParam'
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import moment from 'moment';
import { updateMSRPData } from '../data'
export default defineComponent({
    name: '',
    setup() {
        const router = useRouter() 
        const route = useRoute()
        const store = useStore()
        const { back } = router;
        const { query } = route;

        const make = store.state.make;
        const versionValue = ref<string>(query.version as string)
        const yearValue = ref<string>(query.year as string)
        const currentMonth = (+versionValue.value.substring(versionValue.value?.indexOf('K') + 1)) - 1
        
        // 获取表格的高度
        const { tableHeight } = usetableHeight('#ssHost', '#ssHostvp')
        
        // 设置初始化数据变量
        const dataSource = reactive<UpdateMSRPProps>({
            table: [],
            count: 0,
        })
        
        // 初始化Spread
        // sheet当前表格的初始化
        let workbook: GC.Spread.Sheets.Workbook
        const ssHotTopInit = () => {
            const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
            workbook = spread;
            const currentYear = moment().format('YYYY')
            const allYear = currentYear < yearValue.value ? 0 : currentMonth
            ssHotTop(spread, dataSource.table, allYear)
        }
        
        // 获取数据
        const initKufriPanelData = () => {
            const params = {
                make: make,
                version: versionValue.value,
                year: yearValue.value,
            }
            getMSRPData({params}).then(res => {
                Object.assign(dataSource, res)
                if (!workbook) {
                    ssHotTopInit()
                }
            })
        }

        const save = () => {
            const data = dataSource.table;
            const isEmpty = getIsEmpty(data)
            if (isEmpty) {
                message.error('保存失败，存在空数据，请填写后提交')
            } else {
                const saveData = [];
                for (let rowInd = 0; rowInd < data.length; rowInd++) {
                    const rowData = Object.assign( data[rowInd], {
                        version: versionValue.value,
                        year: Number(yearValue.value),
                    })
                    saveData.push(rowData)
                }
                saveMSRPData(saveData).then(res => {
                    message.success('Save successfully!')    
                    initKufriPanelData()             
                })
            } 
        }
        
        
        // 导出表格
        const handlerExport = () => {
            if (versionValue.value && yearValue.value ) {
                const config = {
                    url: '/vapi/msrp/detail/export',
                    params: {
                        make: make,
                        version: versionValue.value,
                        year: yearValue.value,
                    }
                }
                blobDownload(config)
            } else {
                message.error("Please select parameters")
            }
        }
        
        onMounted(() => {
            initKufriPanelData()
        })

        onBeforeUnmount(() => {
            workbook.destroy();
        })

        return {
            // table的高度
            tableHeight,
            save,
            // 导出文件
            handlerExport,
            back,

            ...toRefs(dataSource),

            versionValue,
            yearValue,
        }

    }
 });
