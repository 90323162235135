import GC from '@grapecity/spread-sheets'
import { reactive, toRefs, onMounted,  } from 'vue';
import { UpdateMSRPData, IcolInfosData } from './types'
// import { getMonth } from '@/utils'
interface TablePosition {
    tableHeight: number;
}

const columnsWidth = {
    'Brand': 75,
    'Type Class': 90,
    'Make': 90,
    'NST group': 165,
    'NST Group Name': 180,
}

export const colInfos: IcolInfosData[] = [
    {
        displayName: 'Brand',
        name: 'Brand',
    },{
        displayName: 'Type Class',
        name: 'Type Class',
    },{
        displayName: 'Make',
        name: 'Make',
    },{
        displayName: 'NST Group',
        name: 'NST Group',
    },{
        displayName: 'NST Group Name',
        name: 'NST Group Name',
    },{
        displayName: 'Jan',
        name: 'Jan',
    },{
        displayName: 'Feb',
        name: 'Feb',
    },{
        displayName: 'Mar',
        name: 'Mar',
    },{
        displayName: 'Apr',
        name: 'Apr',
    },{
        displayName: 'May',
        name: 'May',
    },{
        displayName: 'Jun',
        name: 'Jun',
    },{
        displayName: 'Jul',
        name: 'Jul',
    },{
        displayName: 'Aug',
        name: 'Aug',
    },{
        displayName: 'Sep',
        name: 'Sep',
    },{
        displayName: 'Oct',
        name: 'Oct',
    },{
        displayName: 'Nov',
        name: 'Nov',
    },{
        displayName: 'Dec',
        name: 'Dec',
    }
]

let dataCount: number;
const colCount: number = colInfos.length;
// NST Group Name列索引
const frozenIndex = colInfos.findIndex(item => item.displayName === 'NST Group Name')
// 当前月
let currentMonth: number;

// 计算表格的高度
export const usetableHeight = (id: string, idTop?: string) => {
    const tablePosition = reactive<TablePosition>({tableHeight: 200});
    const resizeHandler = (): void => {
        try {
            // 获取body的高度
            const clientHeight: number = document.body.clientHeight;
            const tableOffsetTop: number = (document.querySelector(id) as HTMLDivElement).getBoundingClientRect().top;
            
            tablePosition.tableHeight = clientHeight - tableOffsetTop - 20;
            (document.querySelector(id) as HTMLDivElement).style.height = tablePosition.tableHeight + 'px'
        } catch (e) {
            console.log(e);
        }
    }
    onMounted(() => {
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
    })

    return toRefs(tablePosition);
}

// 设置这一列是否需要过滤的功能
const setFilter = (frozenIndex: number ,table: GC.Spread.Sheets.Tables.Table) => {
    for (let index = 0; index < colCount; index++) {
        if (index > frozenIndex) {
            // 不显示过滤按钮
            table.filterButtonVisible(index, false)
        }
    }
}

// 设置宽度
const setColumnWidth = (sheet: GC.Spread.Sheets.Worksheet) => {
    colInfos.forEach((item, index) => {
        if (!columnsWidth[item.displayName]) {
            sheet.setColumnWidth(index, 100)
        } else {
            sheet.setColumnWidth(index, columnsWidth[item.displayName])
        }
    })
}

// 设置表头及其单元格类型
const setHeaderType = (spread: GC.Spread.Sheets.Workbook) => {
    const tableColumns: GC.Spread.Sheets.Tables.TableColumn[] = []
    colInfos.forEach((item, index) => {
        const column: GC.Spread.Sheets.Tables.TableColumn = new GC.Spread.Sheets.Tables.TableColumn(index, item.name, item.displayName)
        tableColumns.push(column)
    })
    return tableColumns
}

export const setEmptyCellStyle = (sheet: GC.Spread.Sheets.Worksheet, data: UpdateMSRPData[]) => {
    const dataCount = data.length;
    const col = currentMonth + frozenIndex + 1;
    for (let rowInd = 0; rowInd < dataCount; rowInd++) {
        const rowData = data[rowInd]
        for (const [index, value] of colInfos.entries()) {
            // if (index >= col && !rowData[value.name] && rowData[value.name] !== 0) {
            if (!rowData['Brand'] || !rowData['Type Class']) {
                sheet.getCell(rowInd + 1, index).backColor('#ffff00')
            }
            if (index >= col && !rowData[value.name] && rowData[value.name] !== 0) {
                colInfos.forEach((item, index) => {
                    sheet.getCell(rowInd + 1, index).backColor('#ffff00')
                })
            }
        } 
    }
}

// 绑定数据
export const bindDataTable = (spread: GC.Spread.Sheets.Workbook, data: UpdateMSRPData[]) => {
    // 挂起
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    const rowLength = data.length
    sheet.setRowCount(rowLength + 1)
    sheet.setColumnCount(colInfos.length)
    const table = sheet.tables.add('tableSales', 0, 0, dataCount+1, colCount, GC.Spread.Sheets.Tables.TableThemes.light1);
    table.rowFilter().filterDialogVisibleInfo().sortByColor = false;
    // 设置行数 表头和汇总行要多两行
    sheet.setRowCount(data.length + 1);
    sheet.setColumnCount(colCount);

    // 设置冻结列
    sheet.frozenColumnCount(frozenIndex + 1)

    // 样式
    sheet.getRange(0, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#bfbfbf')
    table.bandRows(false);
    // for (let i = 0; i < data.length; i++) {
    //     (i % 2 === 1) && sheet.getRange(i, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#f0f0f0')
    // }

    // 设置冻结行 表头
    sheet.frozenRowCount(1)
    table.showFooter(true);
    table.showHeader(true);
    table.highlightFirstColumn(true);
    table.highlightLastColumn(false);
    table.autoGenerateColumns(false)
    table.bind(setHeaderType(spread), '', data);

    setEmptyCellStyle(sheet, data)

    // 设置宽度
    setColumnWidth(sheet)
    sheet.options.rowHeaderVisible = false

    // 设置过滤的
    setFilter(frozenIndex, table)

    spread.resumePaint();
}

export const inputEventToFormula = (spread: GC.Spread.Sheets.Workbook, data: any) => {
    const sheet = spread.getActiveSheet();
    const spreadNS = GC.Spread.Sheets;
    sheet.bind(GC.Spread.Sheets.Events.ValueChanged, (e: any, args: any) => {
        const text = new Number(args.newValue)
        const numNext = text.valueOf()
        if (sheet.getCell(args.row, args.col).text()) {
            sheet.getCell(args.row, args.col).backColor('#fff')
            sheet.getCell(args.row, args.col).setBorder( new spreadNS.LineBorder( '#d4d4d4' , spreadNS.LineStyle.thin),
                { all: true }
            ); 
            sheet.getCell(args.row, args.col).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
        } else {
            sheet.getCell(args.row, args.col).backColor('#ffff00')
            sheet.getCell(args.row, args.col).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
        }
        // 输入内容为
        if (typeof(numNext) === 'number') {
            if (numNext === 0 || isNaN(numNext)) {
                sheet.setValue(args.row, args.col, null); 
                return
            }
            const row = args.row;
            const col = args.col;

            if (col >= frozenIndex) {
                // 当前列值改变对应后面列值都对应改变为相同值
                for (let i = col; i < colCount; i++){ 
                    sheet.setValue(row, i, text.toString());
                }
            }
        } else {
            // sheet.setValue(args.row, args.col, null); 
        }  
    })
    
    sheet.bind(GC.Spread.Sheets.Events.RangeChanged, (e: any, info: any) => {
        sheet.suspendEvent();
        const editStyle = new GC.Spread.Sheets.Style();
        editStyle.backColor = '#fff';
       
        info.changedCells.forEach((item: any) => { 
            /**
             * 这个粘贴会产生背景色的 bug action是触发事件的类型
            */
           if (info.action === 3) {
                if (sheet.getCell(item.row, item.col).text()) {
                    sheet.getCell(item.row, item.col).backColor('#fff')
                    sheet.getCell(item.row, item.col).setBorder( new spreadNS.LineBorder( '#d4d4d4' , spreadNS.LineStyle.thin),
                        { all: true }
                    ); 
                    sheet.getCell(item.row, item.col).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
                } else {
                    sheet.getCell(item.row, item.col).backColor('#ffff00')
                    sheet.getCell(item.row, item.col).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
                }         
                const codeValue = sheet.getCell(item.row, item.col).value()
                // sheet.getCell(item.row, item.col).backColor('#fff')
                const text = new Number(codeValue)
                const numNext = text.valueOf()
                if (typeof(numNext) === 'number') {
                    if (numNext === 0 || isNaN(numNext)) {
                        sheet.setValue(item.row, item.col, null); 
                        return
                    }
                    const row = item.row;
                    const col = item.col;
                    if (col >= frozenIndex) {
                        for (let i = col; i < colCount; i++){ 
                            sheet.setValue(row, i, text.toString());
                            sheet.getCell(row, i).backColor('#fff')
                        }
                    }
                } else {
                    sheet.setValue(item.row, item.col, null); 
                }
           }

        })
        // bindDataTable(spread, data)

        sheet.resumeEvent();
    })
}

// 初始化配置
export const ssHotTop = (spread: GC.Spread.Sheets.Workbook, data: any, queryMonth: number,) => {
    const sheet = spread.getActiveSheet();
    const spreadNS = GC.Spread.Sheets;
    spread.suspendPaint();
    dataCount = data.length;
    currentMonth = queryMonth
    // 滚动条样式
    // sheet是否显示x
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    sheet.options.gridline.showHorizontalGridline = false
    sheet.options.gridline.showVerticalGridline = false

    // 滚动条常用设置
    spread.options.scrollbarMaxAlign = true

    // 表格保护
    sheet.options.isProtected = true

    sheet.options.clipBoardOptions = spreadNS.ClipboardPasteOptions.values;
    //填充铺满整个canvas
    spread.options.scrollbarMaxAlign = true;
    spread.options.scrollByPixel = true;

    //自适应的时候同时测量 header 区域和 Viewport 区域
    spread.options.autoFitType = spreadNS.AutoFitType.cellWithHeader;

    // 设置隐藏头和列
    sheet.options.rowHeaderVisible = false
    // sheet.options.colHeaderVisible = false

    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();

    //修改并设置表的默认样式locked为false.
    sheetStyle.locked = true;

    //默认对其方式
    sheetStyle.hAlign = spreadNS.HorizontalAlign.center;
    sheetStyle.vAlign = spreadNS.VerticalAlign.center;
    sheet.setDefaultStyle(sheetStyle);

    // 当前月之前的单元格为灰色的，不可编辑
    const grayStyle = new spreadNS.Style();
    grayStyle.locked = true;
    grayStyle.backColor = '#f0f0f0';

    // 允许编辑单元格
    const editStyle = new spreadNS.Style();
    editStyle.locked = false;
    editStyle.backColor = '#fff';

    const rowIndArr: number[] = []
    // 数据显示格式
    for (let rowInd = 0; rowInd < dataCount; rowInd++) {
        const rowData = data[rowInd]
        
        colInfos.forEach((item, index) => {
        
            // MSRP数据格式:千分符、保留两位小数
            
            // 大于当前月
            if (index > currentMonth + frozenIndex){
                // 判断是否有值
                if (!rowData[item.name] && rowData[item.name] !== 0) {    
                    rowIndArr.push(rowInd)
                    sheet.setStyle(rowInd + 1, index, editStyle.clone());
                    sheet.setFormatter(rowInd + 1, index, "#,##", spreadNS.SheetArea.viewport);
                }
                sheet.getCell(rowInd + 1, index).setBorder( new spreadNS.LineBorder( '#d4d4d4' , spreadNS.LineStyle.thin),
                    { all: true }
                );
            } else {
               
                // 小于当前月都不可以编辑
                if ( rowInd === 4 || rowInd === 2) {
                    // sheet.setStyle(rowInd + 1, index, grayStyle);
                }
                sheet.setStyle(rowInd + 1, index, grayStyle.clone());
            }

            if (index > frozenIndex) {
                sheet.setFormatter(rowInd + 1, index, "#,##", spreadNS.SheetArea.viewport);
                sheet.getCell(rowInd + 1, index).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
                // sheet.getCell(0, index).hAlign(GC.Spread.Sheets.HorizontalAlign.right)

            } else {
                if (index === 0 || index === 1) {
                    sheet.getCell(rowInd + 1, index).hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                } else {
                    sheet.getCell(rowInd + 1, index).hAlign(GC.Spread.Sheets.HorizontalAlign.left)
                }
                // sheet.getCell(0, index).hAlign(GC.Spread.Sheets.HorizontalAlign.left)
            }
            
        })
    }

    

    // 设置整个表格不能插入， 删除行列
    // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
    // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
    const option = {
        allowSelectLockedCells: true,
        allowSelectUnlockedCells: true,
        allowFilter: true,
        allowSort: true,
        allowResizeRows: false,
        allowResizeColumns: true,
        allowEditObjects: false,
        allowDragInsertRows: false,
        allowDragInsertColumns: false,
        allowInsertRows: false,
        allowInsertColumns: false,
        allowDeleteRows: false,
        allowDeleteColumns: false,
        allowUserZoom: false,
        allowUserDragFill: false,
        allowUserDragDrop: false,
    };
    sheet.options.protectionOptions = option;
    
    // 绑定数据
    // if (dataCount > 0) bindDataTable(spread, data)
    bindDataTable(spread, data)
    inputEventToFormula(spread, data)
    spread.resumePaint();
}

// 判断是否存在空单元格
export const getIsEmpty = (data: UpdateMSRPData[]) => {
    const dataCount = data.length;
    const col = currentMonth + frozenIndex + 1;
    for (let rowInd = 0; rowInd < dataCount; rowInd++) {
        const rowData = data[rowInd]
        for (const [index, value] of colInfos.entries()) {
            if (index >= col && !rowData[value.name] && rowData[value.name] !== 0) {
                return true
            }
            if (!rowData['Brand'] || !rowData['Type Class']) {
                return true
            }
        } 
    }
    return false
}